import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import AccessAllowedRoute from "./accessAllowedRoute";

const Opportunity = lazy(() => import("../views/Opportunity"));
const Opportunities = lazy(() => import("../views/Opportunities"));
const Assignment = lazy(() => import("../views/Assignment"));
const Assignments = lazy(() => import("../views/Assignments"));
const AnnualInvestmentCharters = lazy(() => import("../views/AnnualInvestmentCharters"));
const AnnualInvestmentChartersConfirm = lazy(() => import("../views/AnnualInvestmentCharters/Confirm"));
const Contract = lazy(() => import("../views/Contract"));
const Contracts = lazy(() => import("../views/Contracts"));
const Home = lazy(() => import("../views/Home"));
const Investors = lazy(() => import("../views/Investors"));
const Investor = lazy(() => import("../views/Investor"));
const Products = lazy(() => import("../views/Products"));
const Product = lazy(() => import("../views/Product"));
const SharedDocuments = lazy(() => import("../views/SharedDocuments"));
const NotFound = lazy(() => import("../views/NotFound"));

const CustomRoutes = () => {
	return (
		<Suspense>
			<Routes>
				<Route path="/" element={<AccessAllowedRoute element={<Home />} pageCategory={"Home"} />} />
				<Route
					path="/annualInvestmentCharters"
					element={<AccessAllowedRoute element={<AnnualInvestmentCharters />} pageCategory={"AnnualInvestmentCharters"} />}
				/>
				<Route
					path="/annualInvestmentChartersConfirm"
					element={<AccessAllowedRoute element={<AnnualInvestmentChartersConfirm />} pageCategory={"AnnualInvestmentCharters"} />}
				/>
				<Route path="/opportunities" element={<AccessAllowedRoute element={<Opportunities />} pageCategory={"Opportunities"} />} />
				<Route path="/opportunities/:id" element={<AccessAllowedRoute element={<Opportunity method="view" />} pageCategory={"Opportunity"} />} />
				<Route path="/assignments" element={<AccessAllowedRoute element={<Assignments />} pageCategory={"Assignments"} />} />
				<Route path="/assignments/:productId" element={<AccessAllowedRoute element={<Assignment method="view" />} pageCategory={"Assignment"} />} />
				<Route path="/contracts" element={<AccessAllowedRoute element={<Contracts />} pageCategory={"Contracts"} />} />
				<Route path="/contracts/:id" element={<AccessAllowedRoute element={<Contract method="view" />} pageCategory={"Contract"} />} />
				<Route path="/investors" element={<AccessAllowedRoute element={<Investors />} pageCategory={"Investors"} />} />
				<Route path="/investors/:id" element={<AccessAllowedRoute element={<Investor method="view" />} pageCategory={"Investor"} />} />
				<Route path="/products" element={<AccessAllowedRoute element={<Products />} pageCategory={"Products"} />} />
				<Route path="/products/:id" element={<AccessAllowedRoute element={<Product method="view" />} pageCategory={"Product"} />} />
				<Route path="/sharedDocuments" element={<AccessAllowedRoute element={<SharedDocuments />} pageCategory={"SharedDocuments"} />} />
				<Route path="*" element={<NotFound />} />
			</Routes>
		</Suspense>
	);
};

export default CustomRoutes;
