// Actions
import { pageActions as actions } from "./actions";

// Types
import { AnyAction } from "redux";

export type PageStateType = {
	header_title: string;
};

// Default state
const defaultState: PageStateType = {
	header_title: ""
};

export const reducer = (state = defaultState, action: AnyAction) => {
	const { payload } = action;

	switch (action.type) {
		case actions.UPDATE_PAGE_HEADER_TITLE:
			return { header_title: payload };
		default:
			return state;
	}
};
