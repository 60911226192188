// Helpers
import { dispatch } from "../../helpers/actions";
const USER_TYPE = "USER_TYPE";
const USER_TYPE_ID = "USER_TYPE_ID";
const USER_EMAIL = "USER_EMAIL";
const USER_ID = "USER_ID";

export const userActions = {
	USER_TYPE,
	USER_TYPE_ID,
	USER_EMAIL,
	USER_ID,
	setUserType: (payload: string) => dispatch(USER_TYPE, payload),
	setUserTypeId: (payload: number) => dispatch(USER_TYPE_ID, payload),
	setUserEmail: (payload: string) => dispatch(USER_EMAIL, payload),
	setUserId: (payload: number) => dispatch(USER_ID, payload)
};
