import { applyMiddleware, combineReducers, compose, legacy_createStore as createStore } from "redux";
import { reducer as pageReducer } from "./page";
import { reducer as userReducer } from "./user";
import { createLogger } from "redux-logger";

const logger = createLogger({
	collapsed: true,
	level: "log"
});

export default function configureStore() {
	const reducers = {
		page: pageReducer,
		user: userReducer
	};

	// In development, use the browser's Redux dev tools extension if installed
	const enhancers: any = [];
	const isDevelopment = process.env.NODE_ENV === "development";

	const rootReducer = combineReducers({
		...reducers
	});

	const initialState: any = {};
	const store = isDevelopment
		? createStore(rootReducer, initialState, compose(applyMiddleware(logger), ...enhancers))
		: createStore(rootReducer, initialState, ...enhancers);

	return store;
}
