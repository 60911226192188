/* eslint-disable prettier/prettier */
import { Link, NavLink } from "react-router-dom";
import { Nav } from "react-bootstrap";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Dispatch } from "redux";
// Styles
import styles from "./index.module.css";
import logo from "../../../assets/images/taxmm-logo.svg";
import logoMini from "../../../assets/images/taxmm-logo.svg";
import { MdLocalPlay, MdShoppingCart } from "react-icons/md";
import { FaFileContract } from "react-icons/fa";
import { IoIosPeople } from "react-icons/io";
import { connect } from "react-redux";
import checkPermissions from "../../../helpers/checkPermissions";

type Props = {
	displayNavbar: boolean;
	userType: string;
	userTypeId: number;
};

function SideBar(props: Props) {
	const matchW = useMediaQuery("(max-width: 1000px)");
	const matchH = useMediaQuery("(max-height: 767px)");
	const heightToSmall = useMediaQuery("(max-height: 524px)");
	const responsive = matchW || matchH;
	let iconSize = responsive ? 30 : 40;
	iconSize = heightToSmall ? 20 : iconSize;
	const { displayNavbar, userType, userTypeId } = props;
	const [displayInvestors, setDisplayInvestors] = React.useState<boolean>(false);
	const [displayOpportunities, setDisplayOpportunities] = React.useState<boolean>(false);
	const [displayAssignments, setDisplayAssignments] = React.useState<boolean>(false);
	const [displayContracts, setDisplayContracts] = React.useState<boolean>(false);
	const [displayProducts, setDisplayProducts] = React.useState<boolean>(false);
	const [displaySharedDocuments, setDisplaySharedDocuments] = React.useState<boolean>(false);

	React.useEffect(() => {
		setDisplayInvestors(checkPermissions(userType, userTypeId, "Investors").view);
		setDisplayOpportunities(checkPermissions(userType, userTypeId, "Opportunities").view);
		setDisplayAssignments(checkPermissions(userType, userTypeId, "Assignments").view);
		setDisplayContracts(checkPermissions(userType, userTypeId, "Contracts").view);
		setDisplayProducts(checkPermissions(userType, userTypeId, "Products").view);
		setDisplaySharedDocuments(checkPermissions(userType, userTypeId, "SharedDocuments").view);
	}, [userType, userTypeId]);

	return (
		<div className={styles.SideBar} style={{ display: displayNavbar ? "inline" : "none" }}>
			<div className={styles.SideBarContent}>
				{!heightToSmall && (
					<Link to="/">
						<img src={responsive ? logoMini : logo} className={responsive ? styles.LogoMini : styles.Logo} alt="logo" />
					</Link>
				)}
				<Nav className={styles.LinkList}>
					{displayInvestors && (
						<div className={styles.LinkContainer}>
							<NavLink className={navData => (navData.isActive ? "ActiveSideBarLink" : "SideBarLink")} to="/investors">
								<IoIosPeople size={iconSize} />
								<br />
								<div className={styles.LinkName}>
									<FormattedMessage id="sidebar.investors" />
								</div>
							</NavLink>
						</div>
					)}
					{displayOpportunities && (
						<div className={styles.LinkContainer}>
							<NavLink className={navData => (navData.isActive ? "ActiveSideBarLink" : "SideBarLink")} to="/opportunities">
								<MdShoppingCart size={iconSize} />
								<br />
								<div className={styles.LinkName}>
									<FormattedMessage id="sidebar.opportunities" />
								</div>
							</NavLink>
						</div>
					)}
					{displayAssignments && (
						<div className={styles.LinkContainer}>
							<NavLink className={navData => (navData.isActive ? "ActiveSideBarLink" : "SideBarLink")} to="/assignments">
								<MdShoppingCart size={iconSize} />
								<br />
								<div className={styles.LinkName}>
									<FormattedMessage id="sidebar.assignments" />
								</div>
							</NavLink>
						</div>
					)}
					{displayContracts && (
						<div className={styles.LinkContainer}>
							<NavLink className={navData => (navData.isActive ? "ActiveSideBarLink" : "SideBarLink")} to="/contracts">
								<FaFileContract size={iconSize} />
								<br />
								<div className={styles.LinkName}>
									<FormattedMessage id="sidebar.contracts" />
								</div>
							</NavLink>
						</div>
					)}
					{displayProducts && (
						<div className={styles.LinkContainer}>
							<NavLink className={navData => (navData.isActive ? "ActiveSideBarLink" : "SideBarLink")} to="/products">
								<MdLocalPlay size={iconSize} />
								<br />
								<div className={styles.LinkName}>
									<FormattedMessage id="sidebar.products" />
								</div>
							</NavLink>
						</div>
					)}
					{displaySharedDocuments && (
						<div className={styles.LinkContainer}>
							<NavLink className={navData => (navData.isActive ? "ActiveSideBarLink" : "SideBarLink")} to="/sharedDocuments">
								<MdLocalPlay size={iconSize} />
								<br />
								<div className={styles.LinkName}>
									<FormattedMessage id="sidebar.sharedDocuments" />
								</div>
							</NavLink>
						</div>
					)}
				</Nav>
			</div>
		</div>
	);
}

const mapDispatchToProps = (dispatch: Dispatch) => ({});

const mapStateProps = (state: any) => ({ userType: state.user.userType, userTypeId: state.user.userTypeId });

export default connect(mapStateProps, mapDispatchToProps)(SideBar);
