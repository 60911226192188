import React from "react";
import "./App.css";
import Body from "./components/PageLayout/Body";
import Footer from "./components/PageLayout/Footer";
import Header from "./components/PageLayout/Header";
import CustomNavbar from "./components/PageLayout/SideBar";
import CustomRoutes from "./routes";
import { AuthenticatedTemplate, MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { EventType, InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import ScrollToTop from "./components/ScrollToTop";
import { Dispatch } from "redux";
import { userActions as actions } from "./store/user";
import { connect } from "react-redux";
import userServices from "./services/userServices";

type Props = {
	email: string;
	setUserType: (type: string) => void;
	setUserTypeId: (typeId: number) => void;
	setUserEmail: (email: string) => void;
	setUserId: (userId: number) => void;
};

const App = (props: Props) => {
	const { email, setUserEmail, setUserTypeId, setUserType, setUserId } = props;
	const [displayNavbar, setDisplayNavbar] = React.useState<boolean>(true);
	const { instance } = useMsal();
	const [userAllowed, setUserAllowed] = React.useState<boolean>(false);

	React.useEffect(() => {
		const accounts = instance.getAllAccounts();
		if (accounts.length > 0) {
			instance.setActiveAccount(accounts[0]);
			setUserEmail(accounts[0]?.username!);
		}

		instance.addEventCallback((event: { eventType: EventType; payload: { account: any } }) => {
			// set active account after redirect
			if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
				const account = event.payload.account;
				instance.setActiveAccount(account);
				setUserEmail(account?.username!);
			}
		});
	}, [instance, setUserEmail]);

	React.useEffect(() => {
		if (email) {
			userServices.UserAccess(email).then(response => {
				if (response.allowed) {
					setUserAllowed(response.allowed);
					setUserType(response.type);
					setUserTypeId(response.typeId);
					if (response.id !== undefined) {
						setUserId(response.id);
					}
				} else {
					instance.logoutRedirect({
						postLogoutRedirectUri: "/"
					});
				}
			});
		}
	}, [email, instance, setUserType, setUserTypeId, setUserId]);

	return (
		<div className="App">
			<MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
				<CustomNavbar displayNavbar={displayNavbar} />
				<div className="AppContainer">
					<Header
						displayNavBar={display => {
							setDisplayNavbar(display);
						}}
					/>
					<Body>
						<AuthenticatedTemplate>
							<ScrollToTop />
							{userAllowed && <CustomRoutes />}
						</AuthenticatedTemplate>
					</Body>
					<Footer />
				</div>
			</MsalAuthenticationTemplate>
		</div>
	);
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	setUserType: (type: string) => dispatch(actions.setUserType(type)),
	setUserTypeId: (typeId: number) => dispatch(actions.setUserTypeId(typeId)),
	setUserEmail: (email: string) => dispatch(actions.setUserEmail(email)),
	setUserId: (id: number) => dispatch(actions.setUserId(id))
});

const mapStateProps = (state: any) => ({
	email: state.user.userEmail
});

export default connect(mapStateProps, mapDispatchToProps)(App);
