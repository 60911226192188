// Actions
import { userActions as actions } from "./actions";

// Types
import { AnyAction } from "redux";

export type UserStateType = {
	userType: string | null;
	userTypeId: number | null;
	userEmail: string | null;
	userId: string | null;
};

// Default state
const defaultState: UserStateType = {
	userType: null,
	userTypeId: null,
	userEmail: null,
	userId: null
};

export const reducer = (state = defaultState, action: AnyAction) => {
	const { payload } = action;

	switch (action.type) {
		case actions.USER_TYPE:
			return { ...state, userType: payload };
		case actions.USER_TYPE_ID:
			return { ...state, userTypeId: payload };
		case actions.USER_EMAIL:
			return { ...state, userEmail: payload };
		case actions.USER_ID:
			return { ...state, userId: payload };
		default:
			return state;
	}
};
