const NotFound = () => {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				height: "100vh",
				backgroundColor: "#f0f0f0"
			}}
		>
			<h1
				style={{
					fontSize: "4rem",
					color: "#333",
					marginBottom: "1rem",
					maxWidth: "100%",
					textAlign: "center"
				}}
			>
				404 <br />
				Not Found
			</h1>
			<p
				style={{
					fontSize: "1.5rem",
					color: "#666",
					textAlign: "center"
				}}
			>
				Lo sentimos, no hemos podido encontrar la página que estabas buscando.
			</p>
		</div>
	);
};

export default NotFound;
