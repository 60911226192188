/* eslint-disable prettier/prettier */
type Configuration = {
	PROXY_URL?: string;
};

var config: Configuration = {};

switch (process.env.NODE_ENV) {
	case "production":
		config = {
			PROXY_URL: "#{REACT_APP_PROXY_URL}#"
		};
		break;

	default:
		config = {
			PROXY_URL: process.env.REACT_APP_PROXY_URL
		};
		break;
}

export default config;
