/* eslint-disable prettier/prettier */
export type PageCategory =
	| "Home"
	| "Opportunities"
	| "Opportunity"
	| "Contracts"
	| "Contract"
	| "Investors"
	| "Investor"
	| "Products"
	| "Product"
	| "SharedDocuments"
	| "AnnualInvestmentCharters"
	| "Assignments"
	| "Assignment";

const allowedCategories = {
	finder: ["Home", "Investors", "Investor", "Contracts", "Contract", "Opportunities", "Opportunity"],
	investor: ["AnnualInvestmentCharters", "Home", "Contracts", "Contract", "Opportunities", "Opportunity", "SharedDocuments"],
	producer: ["Home", "Products", "Product", "Assignments", "Assignment", "SharedDocuments"]
};

interface Permissions {
	view: boolean;
	edit: boolean;
}

function checkPermissions(userType: string, userTypeId: number, pageCategory: PageCategory): Permissions {
	let view = false;
	let edit = false;

	if (userType?.toLowerCase() === "admin" && userTypeId === 1) {
		//Caso Admin
	} else if (userType?.toLowerCase() === "investor" && userTypeId === 2) {
		if (allowedCategories.investor.includes(pageCategory)) {
			return { view: true, edit: false };
		}
	} else if (userType?.toLowerCase() === "producer" && userTypeId === 3) {
		if (allowedCategories.producer.includes(pageCategory)) {
			return { view: true, edit: false };
		}
	} else if (userType?.toLowerCase() === "finder" && userTypeId === 4) {
		if (allowedCategories.finder.includes(pageCategory)) {
			return { view: true, edit: false };
		}
	}

	return { view, edit };
}

export default checkPermissions;
