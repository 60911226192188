//@ts-ignore
import React from "react";
import { createRoot } from "react-dom/client";
import "./polyfills";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import customConfigureStore from "./store/configureStore";
import messages_spanish from "./translations/es-ES.json";
import { initializeIcons } from "@uifabric/icons";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

const messages = {
	"es-ES": messages_spanish
};

const language = "es-ES";

initializeIcons(undefined, { disableWarnings: true });

const pca = new PublicClientApplication(msalConfig);

const store = customConfigureStore();
const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);

root.render(
	<Provider store={store}>
		<IntlProvider locale={language} messages={messages[language]}>
			<BrowserRouter>
				{/* <React.StrictMode> */}
				<MsalProvider instance={pca}>
					<App />
				</MsalProvider>
				{/* </React.StrictMode> */}
			</BrowserRouter>
		</IntlProvider>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
