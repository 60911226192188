import axios from "axios";
import config from "../config";
const proxy: string | undefined = config.PROXY_URL;

export type UserAccessResponseDto = {
	id?: number;
	allowed: boolean;
	message?: string;
	type: string;
	typeId: number;
};

class UserServices {
	public UserAccess = async (email: string): Promise<UserAccessResponseDto> => {
		let url = `${proxy}/user/access/${encodeURIComponent(email)}`;
		const req = await axios.get(url);
		return req.data;
	};
}

const userServices = new UserServices();
export default userServices;
