import * as React from "react";
import { connect } from "react-redux";
import NotFound from "../views/NotFound";
import checkPermissions, { PageCategory } from "../helpers/checkPermissions";

type Props = {
	element: any;
	roleStatus: string;
	pageCategory: PageCategory;
	userType: string;
	userTypeId: number;
	editPermissionRequired?: boolean;
};

const AccessAllowedRoute = (props: Props) => {
	const { element, roleStatus, pageCategory, userType, userTypeId, editPermissionRequired } = props;
	const [viewAllowed, setViewAllowed] = React.useState<boolean | undefined>();

	React.useEffect(() => {
		let permissions = checkPermissions(userType, userTypeId, pageCategory);
		setViewAllowed(editPermissionRequired ? permissions.edit : permissions.view);
	}, [pageCategory, roleStatus, userType, userTypeId, editPermissionRequired]);

	if (viewAllowed === undefined) return null;
	if (viewAllowed === false) return <NotFound></NotFound>;

	return element;
};

const mapStateToProps = (state: any) => ({
	roleStatus: state.user.roleStatus,
	userType: state.user.userType,
	userTypeId: state.user.userTypeId
});

export default connect(mapStateToProps)(AccessAllowedRoute);
