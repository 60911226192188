import { PublicClientApplication, InteractionType } from "@azure/msal-browser";

export const b2cPolicies = {
	names: {
		signUpSignIn: "B2C_1_Basico"
	},
	authorities: {
		signUpSignIn: {
			authority: "https://taxmm.b2clogin.com/taxmm.onmicrosoft.com/B2C_1_Basico"
		}
	},
	authorityDomain: "taxmm.b2clogin.com"
};

export const apiConfig = {
	b2cScopes: ["https://taxmm.onmicrosoft.com/tasks/tasks.read"]
};

export const msalConfig = {
	auth: {
		clientId: "62663635-8298-4cab-852e-b79a78773a1c",
		authority: b2cPolicies.authorities.signUpSignIn.authority,
		knownAuthorities: [b2cPolicies.authorityDomain],
		redirectUri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}`
	},
	cache: {
		cacheLocation: "localStorage",
		storeAuthStateInCookie: false
	}
};

export const loginRequest = {
	scopes: ["openid", "email", "profile", ...apiConfig.b2cScopes]
};

export const tokenRequest = {
	scopes: [...apiConfig.b2cScopes],
	forceRefresh: false
};

const pca = new PublicClientApplication(msalConfig);

export async function acquireToken() {
	try {
		const accounts = pca.getAllAccounts();
		if (accounts.length === 0) {
			return null;
		}

		const silentRequest = {
			scopes: ["openid", "email", "profile", "https://taxmm.onmicrosoft.com/62663635-8298-4cab-852e-b79a78773a1c/customScope"],
			account: accounts[0],
			interactionType: InteractionType.Silent
		};

		const authResult = await pca.acquireTokenSilent(silentRequest);
		return authResult?.accessToken;
	} catch (error) {
		console.error("Error acquiring silent access token:", error);
		return null;
	}
}
