import styles from "./index.module.css";
import { FormattedMessage } from "react-intl";

const Footer = () => {
	return (
		<footer id="footer" className={styles.footer}>
			<div className={styles.footerText}>
				<p>
					<FormattedMessage id="footer" />
				</p>
			</div>
		</footer>
	);
};

export default Footer;
